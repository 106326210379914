<script>
import { productService } from "../../../helpers/backend/product.service"
export default {
  data() {
    return {
      form: {
        id: null,
        name: "",
        description: "",
      },
      busy: false,
      show: true,
      showDismissibleAlert: false,
      error: "",
      active: false,
    }
  },
  created() {
    // this.fetchData()
  },
  computed: {
    isUpdateState() {
      return this.form.id != null
    },
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      this.busy = true
      productService.createOrUpdateProductType(this.form).then(
        (data) => {
          if (data != null) {
            this.reset()
            this.$emit("refresh")
            this.showDismissibleAlert = true
            this.error = ""
            this.setActive(false)
          }
          this.busy = false
        },
        (error) => {
          this.showDismissibleAlert = true
          this.error =
            "Failed to Insert/Update Advertisement. Error : " + error
          this.busy = false
        }
      )
    },
    reset() {
      // Reset our form values
      this.form.id = null
      this.form.name = ""
      this.form.description = ""
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.showDismissibleAlert = false
      this.error = ""
      this.$nextTick(() => {
        this.show = true
      })
    },
    onReset(event) {
      event.preventDefault()
      this.setActive(false)
      this.reset()
    },
    setActive(b, outside_triger) {
      this.active = b
      if (outside_triger) return
      this.$emit("active_change", b)
    },
    initUpdate(adId) {
      this.setActive(true)

      this.$nextTick(() => {
        this.reset()
        this.$refs.name.$el.focus()
        this.busy = true
        productService.getProductTypeByID(adId).then(
          (data) => {
            if (data.product_type != null) {
              var jsonData = data.product_type
              this.form.id = jsonData.id
              this.form.name = jsonData.name
              this.form.description = jsonData.description
            } else {
              this.showDismissibleAlert = true
              this.error =
                "Failed to get  product type to update. Error : Product not found"
            }
            this.busy = false
          },
          (err) => {
            this.showDismissibleAlert = true
            this.error = "Failed to get product type to update. Error : " + err
            this.busy = false
          }
        )
      })
    },
    invokeCreate() {
      this.setActive(true)
    },
    // fetchData(){
    //   productService.getAllProductType().then(
    //   data=> {

    //     this.product_type_options = this.product_type_options.concat(data.product_types.map(function (mType) {
    //      return {text : mType.name, value:mType.id}

    //     }))
    //     //alert(typeof(this.machine_types))
    //   },
    //   err => {

    //     this.showDismissibleAlert = true
    //     this.error = "Failed to get product type. Error : " + err
    //   }
    // )
    //}
  },
};
</script>


<template>
  <div v-if="this.active">
    <b-alert
      :variant="error == '' ? 'success' : 'danger'"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
    >
      <div v-if="error != ''">
        {{ error }}
      </div>
      <div v-else>Success perform Insert/Update action</div>
    </b-alert>
    <div class="card">
      <div class="card-body">
        <div>
          <b-form @submit="onSubmit" @reset="onReset" v-if="show" ref="form">
            <b-overlay :show="busy" rounded="lg" opacity="0.6">
              <b-form-group
                id="input-group-1"
                label="Product Name:"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="form.name"
                  type="text"
                  placeholder="Enter Product Type Name"
                  required
                  ref="name"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                label="Product Description:"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="form.description"
                  type="text"
                  placeholder="Enter Product Type Description"
                  required
                  ref="sku"
                ></b-form-input>
              </b-form-group>
              <!-- <b-form-group id="input-group-3" label="Product Brand Name:" label-for="input-3" >
        <b-form-input
          id="input-3"
          v-model="form.brand"
          type="text"
          placeholder="Enter Brand Name"
          required
          ref="brand"
        ></b-form-input>
      </b-form-group>
      <b-form-group id="input-group-4" label="Product Image:" label-for="input-4">
        <b-link v-bind:href="this.media_url" target="_blank" v-show="this.media_url">Click here to see current media</b-link>
        <b-form-file accept="image/*, video/*"
          v-model="form.image"
          id="input-4"
          ref="file"
          v-bind:required="this.form.id == null? true:false"
        ></b-form-file>
      </b-form-group>

      <b-form-group id="input-group-5" label="Product Type: " label-for="input-5">
        <b-form-select
          id="input-5"
          v-model="form.product_type_id"
          :options="product_type_options"
          required
        ></b-form-select>
      </b-form-group>
      <b-form-group id="input-group-6" label="Product Price:" label-for="input-6" >
        <b-form-input
          id="input-5"
          v-model="form.price"
          type="number"
          placeholder="Enter Price cost"
          required
          ref="brand"
        ></b-form-input>
        </b-form-group>
      <b-form-group id="input-group-7" label="Product Description:" label-for="input-7" >
        <b-form-input
          id="input-6"
          v-model="form.description"
          type="text"
          placeholder="Enter Product description"
          required
          ref="sku"
        ></b-form-input></b-form-group> -->

              <b-button class="mr-2" type="submit" variant="primary">
                {{ isUpdateState ? "Update" : "Submit" }}
              </b-button>
              <b-button type="reset" variant="danger">Cancel</b-button>
            </b-overlay>
          </b-form>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <b-form-group>
      <b-button variant="primary" @click="invokeCreate">Create new</b-button>
    </b-form-group>
  </div>
</template>



